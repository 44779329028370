import { ITextStyle } from 'pixi.js';

const font = 'NotoSans-SemiCondensedBold';
const GRADIENT_GOLD_TEXT_COLOR_CONFIG: Partial<ITextStyle> = {
  fill: ['#ffff00', '#ffffa0', '#ffa0a0', '#ff4040', '#ffa0a0'],
  fillGradientType: 0,
  stroke: '#111111',
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1.0,
  dropShadowAngle: 1.0,
  dropShadowBlur: 0,
  dropShadowDistance: 3,
  lineJoin: 'round',
};
const GRADIENT_GOLD_TITLE_COLOR_CONFIG: Partial<ITextStyle> = {
  fill: ['#fff70a', '#fcc200', '#fffb00', '#fcc200', '#b47508'],
  fillGradientStops: [0.2, 0.5, 0.6, 0.7],
  fillGradientType: 0,
  stroke: '#111111',
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1.0,
  dropShadowAngle: 1.0,
  dropShadowBlur: 0,
  dropShadowDistance: 3,
  lineJoin: 'round',
};

export const buyFeatureTitleStyle: Partial<ITextStyle> = {
  fontSize: 70,
  fontFamily: font,
  ...GRADIENT_GOLD_TITLE_COLOR_CONFIG,
  strokeThickness: 8,
  padding: 11,
};

export const buyFeatureConfirmStyle: Partial<ITextStyle> = {
  fontSize: 50,
  fontFamily: font,
  fill: '#ff3333',
  stroke: '#661111',
  strokeThickness: 4,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1.0,
  dropShadowAngle: 1.0,
  dropShadowBlur: 0,
  dropShadowDistance: 3,
  align: 'center',
  lineJoin: 'round',
  padding: 8,
};

export const totalCostTextStyle: Partial<ITextStyle> = {
  fontSize: 50,
  fontFamily: font,
  ...GRADIENT_GOLD_TEXT_COLOR_CONFIG,
  strokeThickness: 4,
  padding: 8,
};

export const totalCostTextAmountStyle: Partial<ITextStyle> = {
  fontSize: 65,
  fontFamily: font,
  lineHeight: 65,
  ...GRADIENT_GOLD_TEXT_COLOR_CONFIG,
  strokeThickness: 5,
};

export const amountTextStyle: Partial<ITextStyle> = {
  fontSize: 50,
  fontFamily: font,
  lineHeight: 50,
  fill: '#f6e87e',
  stroke: '#111111',
  strokeThickness: 4,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1.0,
  dropShadowAngle: 1.0,
  dropShadowBlur: 0,
  dropShadowDistance: 3,
  lineJoin: 'round',
  wordWrap: true,
  wordWrapWidth: 350,
  breakWords: true,
  padding: 8,
};

export const betValueStyle: Partial<ITextStyle> = {
  fontSize: 50,
  fontFamily: font,
  lineHeight: 50,
  fill: '#f6e87e',
  stroke: '#111111',
  strokeThickness: 4,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 0.75,
  dropShadowAngle: 1.0,
  dropShadowBlur: 2,
  dropShadowDistance: 3,
  letterSpacing: 0,
  lineJoin: 'round',
};

export const betValueStyleConfirm: Partial<ITextStyle> = {
  fontSize: 90,
  fontFamily: font,
  lineHeight: 50,
  ...GRADIENT_GOLD_TEXT_COLOR_CONFIG,
  strokeThickness: 7,
};
